.Logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 1%;
}

.flag {
  width: 40px;
}

.Logo__typo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 5px;
}

.Logo__Sony {
  display: block;
  width: 100px;
  margin-left: 5px;
}

.Logo__ExpressNet {
  display: block;
  letter-spacing: 1.5px;
  padding-bottom: 6px;
  font-size: 1.6rem;
  margin-top: 0.5rem;
  font-weight: 600;
  color: #e4e7eb;
}

.Logo__flag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 10px;
}

@media (max-width: 360px) {
  .Logo {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (max-width:400px) {
  a.navbar-brand {
    margin-left: 0 !important;
  }
}