body {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.PriceBook__evenRow,
.PriceBook__oddRow {
  border-bottom: 1px solid #e0e0e0;
}

.PriceBook__oddRow {
  background-color: #fafafa;
}