.SmallImageSlider img {
  max-width: 120px;
  max-height: 120px;
}

.SmallImageSlider:hover {
  border: .5px solid #fafafa;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.SmallImageSlider {
  background-color: #e0e0e0;
  width: 150px;
  height: 150px;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 1px 1px #ccc;
  box-shadow: 0px 1px 1px 1px #ccc;
  margin-right: 3%;
  transition: all .2s ease-in-out;
}

.SmallImageSlider__arrow {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #757575;
  transition: all .2s ease-out;
}

.SmallImageSlider__arrow--next {
  border-left: 1px solid #ccc;
}

.SmallImageSlider__arrow--pre {
  border-right: 1px solid #ccc;
}

.SmallImageSlider__arrow:hover {
  cursor: pointer;
  border-left: 1px solid #ccc;
  color: #212121;
  opacity: .8;
  transform: scale(1);
  transition: all .2s ease-in;
}