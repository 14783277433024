.link {
  color: dodgerblue;
}

.link:hover {
  text-decoration: underline;
}

.react-autosuggest__container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  width: 500px;
  box-sizing: border-box;
}

.react-autosuggest__input {
  width: 100%;
  background: none;
  border: none;
  color: #ffff;
  letter-spacing: 1px;
  padding-bottom: 2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  position: relative;
  color: #000;
  box-sizing: border-box;
}

.react-autosuggest__suggestions-container--open {
  width: 500px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 1.3rem;
  position: absolute;
  z-index: 1000;
  top: 35px;
  left: 0;
  height: 80vh;
  max-height: 400px;
  overflow-y: scroll;
  box-sizing: border-box;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 1% 2%;
  border-bottom-style: solid;
  border-bottom-color: lightgray;
  border-bottom-width: 1px;
  width: 100%;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 1%;
  font-size: 12px;
  color: #777;
}

#searchclear {
  top: 10%;
  bottom: 13px;
  height: 13px;
  margin: auto;
  font-size: 10px;
  cursor: pointer;
  color: rgb(146, 188, 250);
}

.inner-addon {
  position: relative;
  cursor: pointer;
}

/* style icon */

.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
}

.right-addon .glyphicon-right-search {
  -webkit-transform: translate(1400%, 0%);
  transform: translate(1400%, 0%);
}

.right-addon .glyphicon-right-close {
  -webkit-transform: translate(1540%, 0%);
  transform: translate(1540%, 0%);
}

.right-addon input {
  padding-right: 10px;
}

#tooltip-top>.tooltip-inner {
  background-color: #fff;
  color: #000;
  white-space: nowrap;
  max-width: none;
}

#tooltip-top>.tooltip-arrow {
  border-bottom: 6px solid #fff;
}