.EnvironmentDisclaimer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: #fafafa;
  opacity: .8;
}

.EnvironmentDisclaimer__box {
  color: #000;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  background: #e64a19;
  z-index: 99999;
  opacity: 1;
  width: 80vw;
  height: 10vh;
  min-height: 200px;
  max-width: 500px;
  box-shadow: 1px 1px 1px 1px #ccc;
  border-radius: 3px;
  padding: 2% 3%;
  font-size: 2rem;
  font-weight: 600;
  border: 2px solid red;
}

.EnvironmentDisclaimer__btn-ok {
  font-size: 1.6rem;
  font-weight: 500;
  width: 100px;
  height: 30px;
  background: transparent;
  border: 2px solid #000;
  transition: all .3s ease-in;
}

.EnvironmentDisclaimer__btn-ok:hover {
  background-color: #ccc;
  transition: all .3s ease-in;
}