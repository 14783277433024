.product-img {
  border: 1px solid rgb(240, 133, 94);
  border-radius: 6px;
}

.product-seperator-hr {
  border: none;
  height: 1.5px;
  border-radius: 5px;
  background-color: orangered;
  /* Modern Browsers */
}

.fixed {
  padding: 0.2em;
  background-color: #c0c0c0;
  position: fixed;
  border-radius: 5px;
  width: 650px;
  left: 44%;
}

.bs-alert {
  display: none;
}

.fade-out {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  height: 50px;
  width: 20%;
  border-radius: 5px;
  position: fixed;
  top: 125px;
  left: 50%;
  float: left;
  transform: translate(-50%, -50%);
}

.fade-in {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  height: 50px;
  width: 20%;
  border-radius: 5px;
  padding: 1em;
  position: fixed;
  top: 125px;
  left: 50%;
  float: left;
  transform: translate(-50%, -50%);
}

.overflow-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  max-width: 99vw;
  padding: 1% 2%;
}

.__form-inline input {
  margin-left: 5%;
}

.react-table__th {
  font-weight: 600;
}



.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border: none;
}

.react-table__td {
  text-align: center;
  border-left: 1px solid #ccc;
  border-top-right-radius: 1px solid #ccc;
  overflow-wrap: break-word;
}

.react-table__td--text {
  font-size: 1.3rem;
  border-left: 1px solid #ccc;
  border-top-right-radius: 1px solid #ccc;
  overflow-wrap: break-word;
}

/* .ReactTable .rt-thead {
  overflow-y: scroll;
}

.ReactTable .rt-thead::-webkit-scrollbar {
  background: transparent;
} */