.Full-View-Catalog-Menu {
  background-color: #212121;
  color: #fff;
  max-height: 50vh;
  overflow-y: auto;
  padding: 1%;
  padding-left: 2%;
  width: 80vw;
}

.Full-View-Catalog-Menu__category__title {
  font-size: 1.5rem;
  font-weight: 500;
}

.Full-View-Catalog-Menu__category__title--open {
  font-weight: 600;
  color: #01579b;
}

.Full-View-Catalog-Menu__category__title:hover {
  cursor: pointer;
  font-weight: 600;
}

.Full-view-Catalog-Menu__subcategories {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  display: none;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.Full-view-Catalog-Menu__subcategories.open {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  display: block;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1%;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.Full-view-Catalog-Menu__subcategories li {
  list-style-type: square;
  font-size: 1.3rem;
}

.Full-view-Catalog-Menu__subcategories li:hover {
  font-weight: 400;
  text-decoration: underline;
  color: #29b6f6;
  cursor: pointer;
}

.selected-sub {
  color: #29b6f6;
  font-weight: 600;
}