.Help__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Help__header *:not(:last-child) {
  margin-right: 3%;
}

.Help__header__section {
  font-size: 1.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #1a237e;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1% 3%;
  border-radius: 3%;
  color: #01579b;
  -webkit-box-shadow: 0px 11px 10px -15px #111;
  box-shadow: 0px 11px 10px -15px #111;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  text-decoration: none;
}

.Help__header__section:hover * {
  color: #004d40;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  cursor: pointer;
}

.Help__header__section a:hover {
  text-decoration: none;
}

.Help__header__section svg {
  font-size: 2.5rem;
  margin-right: 5px;
}

.nested-header {
  font-size: 1.7rem;
  display: inline-block;
  border-bottom: 2px solid #212121;
  font-weight: 600;
  padding-bottom: 5px;
  margin: 0;
  margin-bottom: 8px;
}

.nested-header__main {
  font-size: 1.6rem;
  display: block;
  font-weight: 600;
  padding-bottom: 5px;
  margin-bottom: 0;
}

.link-group {
  margin-bottom: 10px;
  line-height: 1.2;
}

.link-group h4 {
  font-size: 1.4rem;
  margin-bottom: 0;
  display: block;
  color: #424242;
  font-weight: 600;
}

.link-container {
  padding-left: 1%;
}

.qa__question {
  font-weight: 600;
}

.qa__question p {
  margin-bottom: 0;
}

.qa {
  line-height: 1.3;
}

.qa:not(:last-of-type) {
  margin-bottom: 2%;
}

.FAQSection:not(:first-of-type) {
  margin-top: 3%;
}

.FAQSection__header {
  color: #004d40;
  font-weight: 600;
  font-size: 2rem;
}

@media only screen and (max-width:700px) {
  .Help__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .Help__header *:not(:first-of-type) {
    margin-top: 2%;
  }
}

.Contact-US__section {}

.Contact-US__section h2 {
  font-size: 2rem;
  color: #004d40;
  border-bottom: 3px solid #212121;
  padding-bottom: 2%;
}

.FAQ a {
  color: var(--link-bold-color);
  font-weight: 700;
}