.Header__item {
  color: #fff;
}

.Header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Header__middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-top: 10px;
  margin-left: -55px;
}

.Header__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.Header__container--less-bottom {
  padding-bottom: 3px;
}

.Header__user__self {
  font-weight: 600px;
  font-size: 1.3rem;
}

.Header__user__company {
  font-weight: 400px;
  font-size: 1rem;
}

.Header__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 500px;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #fff;
  box-sizing: border-box;
}

.Header__search__input>input {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.Header__search:hover,
.Header__search__input>input:focus {
  border-bottom-color: #4fc3f7;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* .react-autosuggest__input:focus {
  border-bottom: 5px solid #4fc3f7;
} */

.Header__search__input {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.Header__search__icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Header__icon {
  font-size: 3rem;
  padding-bottom: 5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.Header__icon:hover {
  cursor: pointer;
}

.Header__icon--remove {
  color: red;
}

.Header__icon--search {
  color: #fff;
}

.Header__cart {

  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding-bottom: 2px;
  position: relative;
  margin-top: 10px;
}

.Header__cart--has-item {
  color: #fff;

}

.Header__cart--empty {
  color: #ccc;
}

.cart-badge {
  position: absolute;
  right: -60%;
  top: -20%;
  background-color: #2372D1 !important;
  font-size: 1.2rem;
}

.Header__cart:hover {
  color: #bbbbbb;
  -webkit-transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.Header__cart:active {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.Header__menues {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Header__language-select {
  padding: 2px;
  border-width: 0.15px;
  font-size: 12px;
  border-color: #000;
  color: #000;
  background: transparent;
  color: #fff;
}

.Header__language-select:active,
.Header__language-select:focus {}

.Header__language-select:hover {
  cursor: pointer;
}

.Header__language-select option {
  color: #000;
}

@media (max-width: 1000px) {
  .Header__middle {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
}

@media (max-width: 1117px) {
  .Logo {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .Header__right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 835px) {
  .Header__menues {
    padding-left: 5%;
  }
}

.react-autosuggest__input::-webkit-input-placeholder {
  color: white;
  font-size: 1.5rem;
}

.react-autosuggest__input:-ms-input-placeholder {
  color: white;
}

.react-autosuggest__input::placeholder {
  color: #eceff1;
  font-size: 1.4rem;
}

.Header__cart:active {
  color: inherit;
  border: none;
  outline: none;
}

.Header__cart:visited {
  color: #fff;
}
.logout_wcag{
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
  cursor: pointer;
}

.logout_wcag:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

@media (max-width: 767px) {
  .logout_wcag {
    color: #9d9d9d;
    padding: 5px 15px 5px 25px;
  }

  .logout_wcag:hover {
    color: #fff;
    background-color: transparent;
  }
  
}