.SecondaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c3e50;
  padding: 5px 10px;
  color: #fff;
  margin-top: -20px;
  box-sizing: border-box;
  padding-left: 1%;
  padding-right: 2%;
  flex-wrap: wrap;
  width: 100%;
}

.SecondaryHeader__right {
  display: flex;
  align-self: unset;
  align-items: center;
  justify-content: space-between;
}

.SecondaryHeader__right *:not(:last-child) {
  margin-right: 4px;
}

.SecondaryHeader__typo {
  font-weight: 600;
  font-size: 2rem;
  margin-left: 25px;
  padding-left: 15px;
  color: #fff;
}

@media (max-width: 980px) {
  .Header__search {
    width: 80vw;
    max-width: 80vw;
    margin-top: 10px;
  }

  .react-autosuggest__container {
    order: 1;
    max-width: 80vw;
    width: 80vw;
    margin: auto;
  }

  .react-autosuggest__suggestions-container--open {
    max-width: 80vw;
    width: 80vw;
  }

  .Header__menues {
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

@media (max-width:400px) {
  .SecondaryHeader__typo {
    margin-left: 0;
  }
}

@media (max-width:420px) {
  .SecondaryHeader__right {
    width: 100%;
  }
}