:root {
  --link-bold-color: #1a237e;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 62.5%;
}

*,
*::after,
*::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Lato', 'SST W01 Roman', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 100%;
  position: relative;
  background-color: #f5f5f5;
  color: #212121;
  line-height: 1.6;
}

#root {
  height: 100%;
}

.body-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.container-full-height {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-nav > li > a {
  color: #e4e7eb !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 1.5rem;
}

.navbar-nav > li > a:hover {
  color: #90caf9 !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navbar-inverse {
  background-color: #000;
  border-radius: 0px;
}

.navbar-inverse .container {
  padding: 0px 1%;
  margin: 0;
  width: 100%;
}

.dropdown-submenu {
  padding: 1% 2%;
}

.dropdown-submenu.dropdown.open a[role='button'] {
  font-weight: 900;
}

.helper__hover--pointer:hover {
  cursor: pointer;
}

.helper__display--none {
  display: none !important;
}

.react-datepicker__month-container {
  font-size: 1rem;
}

/* IE configuration */
*::-ms-clear {
  display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* .container-full-height {
    margin-top: 3%;
  } */
  .Header__cart {
    min-width: 70px;
  }

  .Header__cart__icon {
    min-width: 50px;
  }

  .body-wrapper {
    height: 100%;
  }

  .cart-badge {
    right: -5px;
  }

  .button {
    padding-right: 20px;
  }

  .fixed-header th {
    padding-left: 0;
  }
}

@supports (-ms-accelerator: true) {
  /* .container-full-height {
    margin-top: 3%;
  } */
  .Header__cart {
    min-width: 70px;
  }

  .Header__cart__icon {
    min-width: 50px;
  }

  .body-wrapper {
    height: 100%;
  }

  .cart-badge {
    right: -5px;
  }

  .button {
    padding-right: 20px;
  }

  .fixed-header th {
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1330px;
  }
}

@media all and (max-height: 900px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* .container-full-height {
    margin-top: 5%;
  } */
}

/* End of IE Configuration */
.rt-resizable {
  min-width: 150px !important;
}

/* Buttons */
.button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.button__icon {
  font-size: 2rem;
  font-weight: 600px;
  min-width: 20px;
}

.button .button__icon {
  margin-right: 3%;
}

/* end of buttons */
.disabled-element {
  pointer-events: none;
  opacity: 0.4;
}

.form-group {
  width: 100%;
}

.form-group label {
  margin-right: 1%;
}

.react-table-ie .rt-table .rt-tbody {
  max-height: 500px;
  overflow-y: auto;
}

.react-table-ie--customized .rt-table .rt-tbody {
  max-height: 400px;
  overflow-y: auto;
}

.white-space-resolver {
  white-space: pre-line;
}

.toastify-success {
  color: #000;
  border-radius: 5px;
}

.Toastify__toast--default {
  background: transparent;
}

.Toastify__toast {
  box-shadow: none;
}

.Toastify--custom-success {
  background-color: #2778da;
  color: #fff;
  padding: 1% 1%;
  border-radius: 5px;
  text-align: center;
}

.toastify-error {
  background: #b71c1c;
  color: #fff;
  border-radius: 5px;
}

.dropdown-toggle {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-inverse .navbar-nav > li > a {
  padding-top: 0;
  padding-right: 10px;
  padding-bottom: 0;
}

.on-hover-gray:hover {
  color: #ccc;
  transition: all 0.2s ease-in;
}

.on-hover-gray {
  transition: all 0.2s ease-out;
}

.on-hover-cursor:hover {
  cursor: pointer;
}

.reset-style {
  color: inherit;
}

.reset-style:hover {
  color: inherit;
  border: none;
  outline: none;
  text-decoration: none;
}

.reset-style:focus {
  outline: none;
  border: none;
  text-decoration: none;
}

.input-error {
  font-size: 1.2rem;
  color: red;
  font-weight: 600;
  text-align: left;
}

.offscreen {
  position: absolute;
  top: -100%;
  left: -100%;
  display: none;
}

.bold-link,
.bold-link a {
  color: var(--link-bold-color);
  font-weight: 700;
}

.btn-transparent {
  background: transparent;
  border: none;
}
.Select-placeholder, .Select-clear {
  color:#737373 !important;
}
.Select-noresults{
  color:#737373;
}
