.image-div {
  border-style: solid;
  padding: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
  padding-right: 2%;
  border-width: 2px;
  border-radius: 10px;
  border-color: coral;
  width: 550px;
  height: 430px;
}

.form-control-inline {
  display: inline;
}

.pointer {
  cursor: pointer;
}

.sku-link {
  color: dodgerblue;
  cursor: pointer;
}

.sku-link:hover {
  text-decoration: underline;
}

.__form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1%;
}

.ProductDetail__form-inline {
  max-width: 250px;
}

.Feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 1% 0;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 0%;
  padding-right: 0%;
}

.Feature__title {
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  font-weight: 700;
  font-size: 1.4rem;
}

.Feature__description {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Specification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
}

.Specification__name {
  width: 150px;
  padding-right: 5%;
  font-weight: 600;
  font-size: 1.6rem;
}

.Specification__specs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Specification__spec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  padding: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
  padding-right: 2%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  line-height: 1.2;
  flex-basis: auto;
}

.Specification__spec__name {
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  font-weight: 700;
  text-align: left;
  font-size: 1.3rem;
}

.Specification__spec__value {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  word-break: break-all;
}

.Specification__spec:nth-child(2n) {
  background-color: #e3f2fd;
}

.Specification__spec:nth-child(2n + 1) {
  background-color: #eeeeee;
}

.ProductDetail__Specifications {
  max-width: 90vw;
  padding: 1% 3px;
}

.form-inline-multi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.price-table > tbody > tr > td {
  border-top: none !important;
}

.price-table > tbody > tr:not(:first-child) {
  border-top: 1px solid #ddd;
}

.unit-price-table > thead > tr {
  border-bottom: 2px solid #ddd !important;
}


@media only screen and (max-width: 700px) {
  .Specification {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .Specification__name {
    width: 100%;
  }

  .Specification__specs {
    width: 100%;
  }

  .form-inline-multi {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width:1200px) {
  .form-inline-multi {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.ProductDetail__accessory {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
}

.ProductDetail__accessory__sku {
  -ms-flex-preferred-size: 100px;
  flex-basis: 100px;
  font-weight: 600;
}

.ProductDetail__accessory__desc {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/* .ProductDetail__accessory:not(:last-of-type) {
  margin-bottom: 3%;
} */

.ProductDetail__order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 1%;
}

.quantity-input {
  max-width: 50px;
  -webkit-box-flex: 10%;
  -ms-flex: 10%;
  flex: 10%;
}

.PSAProductDetail__form-inline {
  max-width: 300px;
}

.__form-inline .btn {
  margin-left: 10%;
}

.ProductDetail__fixed {
  background-color: #616161;
  opacity: .8;
  padding: 1% 3%;
  line-height: 1.2;
  min-width: 200px;
}

.ProductDetail__fixed__sku {
  display: block;
  font-weight: 600;
  color: #fff;
}

.ProductDetail__fixed__desc {
  font-size: 1.2rem;
  color: #fff;
}

.ProductDetail__collpase-header {
  background-color: #eeeeee;
  -webkit-box-shadow: 1px 1px 1px #ccc;
  box-shadow: 1px 1px 1px #ccc;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
}

.ProductDetail__Features {
  padding: 1% 3px;
}