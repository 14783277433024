.__inline-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 350px;
}

.__inline-group--wide {
  max-width: 700px;
}

.__inline-group label {
  -ms-flex-preferred-size: 40%;
  flex-basis: 50%;
}

.__inline-group .__Field {
  display: inline-block;
}

.__inline-group .__Field input {
  width: 100%;
}

.__inline-group--double {
  max-width: 800px;
}

.__inline-group .__Select {
  display: inline-block;
  -ms-flex-preferred-size: 48%;
  flex-basis: 48%;
}

.form-group--checkbox {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

label~input[type='checkbox'] {
  margin-left: 10px !important;
}

.__inline-group--buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.__inline-group--buttons *:not(:first-child) {
  margin: 0 3%;
}

.__inline-group--buttons--space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .__inline-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .__inline-group .__Field {
    width: 100%;
    margin: 1% 0;
  }

  .__inline-group .__Select {
    width: 100%;
  }

  .form-group--checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .__inline-group--buttons {
    width: 100%;
    min-height: 150px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .__inline-group--buttons * {
    width: 90vw;
    max-width: 150px;
    margin: 10px auto;
  }

  .order-status {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ccc;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #ccc;
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

.__inline-group--large {
  max-width: 700px;
}

.__inline-group--large label {
  -ms-flex-preferred-size: 26%;
  flex-basis: 26%;
}

.__inline-group--large .__Field {
  -ms-flex-preferred-size: 74%;
  flex-basis: 74%;
}

.table-transparent {
  background-color: transparent !important;
}

.input-checkbox {
  display: flex;
  width: 175px;
}

.input-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
}

.stock-input-checkbox {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 3px;
}

.stockError {
  margin-left: 2%;
  margin-bottom:2%
}
.stock-checkbox {
  display: flex;
  width: 160px;
  margin-bottom: 3px;
}

.stock-checkbox label {
  margin-bottom: 0;
}

.stock-checkbox input[type='checkbox'] {
  margin-right: 5px;
  margin-top:0
}

.input-checkbox input[type='checkbox'] {
  margin-top: 5px;
  margin-right: 5px;
}

.form-group--vertical {
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 5px 0px #757575;
  -moz-box-shadow: 0px 0px 5px 0px #757575;
  box-shadow: 0px 0px 5px 0px #757575;
  color: #212121;
  box-sizing: border-box;
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
}

.form-group--vertical h2 {
  font-size: 2rem;
  font-weight: 400;
  background-color: #263238;
  color: #fff;
  height: 3rem;
  padding-top: 3px;
  padding-left: 5px;
  margin-bottom: 10px;
  margin-top: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.form-group__row {
  display: flex;
  flex-wrap: wrap;
}

.form-group--vertical {
  margin-right: 1%;
}

.form-group-fixed-width {
  display: flex;
  width: 330px;
  justify-content: space-between;
  padding-right: 3%;
  padding-left: 3%;
}

.form-group-fixed-width .__Select {
  width: 165px;
}

.form-group-fixed-width:not(:last-of-type) {
  margin-bottom: 10px;
}

.no-shadow {
  box-shadow: none;
}

.search-tools {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-top: 1%;
}

@media (max-width:1475px) {
  /* .form-group--vertical:last-of-type {
    margin-top: 3px;
    margin-right: 0px;
  } */

  /* .form-group--vertical:first-of-type {
    flex: 1;
    width: 100%;
  } */

  /* .form-group--vertical {
    margin-right: 0px;
  } */

  /* .form-group__row {
    justify-content: space-between;
  } */

  /* .form-group--vertical {
    margin-right: 0px;
  } */
  .form-group--vertical {
    margin-top: 2%;

  }
}

@media (max-width:1023px) {
  .form-group--vertical {
    margin-bottom: 2%;
  }
}

@media (max-width:768px) {
  .input-checkbox-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .form-group--vertical {
    width: 100%;
  }
}

@media (max-width:430px) {
  .form-group-fixed-width {
    flex-wrap: wrap;
    width: 100%;
  }

  .form-group-fixed-width>*,
  .form-group-fixed-width>*>*,
  .react-datepicker-wrapper,
  .react-datepicker-wrapper * .form-group-fixed-width:not(.search-tools) {
    width: 100%;
  }

  .form-group-fixed-width label {
    width: 100%;
  }
}