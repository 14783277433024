.Modal__backdropStyle {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
}

.Modal__closeModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: -1;
}

.Modal__modalStyle {
  background-color: #fff;
  border-radius: 5px;
  width: 70vw;
  height: 70vh;
  padding: 30px;
}

.text-danger {
  color: #E00000 !important;
  font-weight: bold;
}

.accordian_button {
  float: right;
  background-color: Transparent;
  border: none;
  outline: none;
  font-size: 1.8rem;
}
