.img-loader {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  animation-name: anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  min-width: 120px;
  min-height: 120px;
}

.hidden {
  display: none;
}

@keyframes anim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}