.loader {
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  position: fixed;
  top: 50vh;
  left: 50vw;
}
@-webkit-keyframes load5 {
  0%,
  100% {
    -webkit-box-shadow: 0em -2.6em 0em 0em #01579b, 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.5), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.7);
    box-shadow: 0em -2.6em 0em 0em #01579b, 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.5), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.7);
  }
  12.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.7), 1.8em -1.8em 0 0em #01579b, 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.2), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.5);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.7), 1.8em -1.8em 0 0em #01579b, 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.2), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.5);
  }
  25% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.5), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.7), 2.5em 0em 0 0em #01579b,
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.2), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.5), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.7), 2.5em 0em 0 0em #01579b,
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.2), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  37.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.5), 2.5em 0em 0 0em rgba(1, 87, 155, 0.7),
      1.75em 1.75em 0 0em #01579b, 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2), -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.5), 2.5em 0em 0 0em rgba(1, 87, 155, 0.7),
      1.75em 1.75em 0 0em #01579b, 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2), -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  50% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.5),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.7), 0em 2.5em 0 0em #01579b, -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2), -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.5),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.7), 0em 2.5em 0 0em #01579b, -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2), -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  62.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.5), 0em 2.5em 0 0em rgba(1, 87, 155, 0.7), -1.8em 1.8em 0 0em #01579b, -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.5), 0em 2.5em 0 0em rgba(1, 87, 155, 0.7), -1.8em 1.8em 0 0em #01579b, -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  75% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.5), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.7), -2.6em 0em 0 0em #01579b,
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.5), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.7), -2.6em 0em 0 0em #01579b,
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  87.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.5),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.7), -1.8em -1.8em 0 0em #01579b;
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.5),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.7), -1.8em -1.8em 0 0em #01579b;
  }
}
@keyframes load5 {
  0%,
  100% {
    -webkit-box-shadow: 0em -2.6em 0em 0em #01579b, 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.5), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.7);
    box-shadow: 0em -2.6em 0em 0em #01579b, 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.5), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.7);
  }
  12.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.7), 1.8em -1.8em 0 0em #01579b, 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.2), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.5);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.7), 1.8em -1.8em 0 0em #01579b, 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.2), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.5);
  }
  25% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.5), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.7), 2.5em 0em 0 0em #01579b,
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.2), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.5), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.7), 2.5em 0em 0 0em #01579b,
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.2), -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  37.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.5), 2.5em 0em 0 0em rgba(1, 87, 155, 0.7),
      1.75em 1.75em 0 0em #01579b, 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2), -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.5), 2.5em 0em 0 0em rgba(1, 87, 155, 0.7),
      1.75em 1.75em 0 0em #01579b, 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2), -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  50% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.5),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.7), 0em 2.5em 0 0em #01579b, -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2), -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.5),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.7), 0em 2.5em 0 0em #01579b, -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.2), -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  62.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.5), 0em 2.5em 0 0em rgba(1, 87, 155, 0.7), -1.8em 1.8em 0 0em #01579b, -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.5), 0em 2.5em 0 0em rgba(1, 87, 155, 0.7), -1.8em 1.8em 0 0em #01579b, -2.6em 0em 0 0em rgba(1, 87, 155, 0.2),
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  75% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.5), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.7), -2.6em 0em 0 0em #01579b,
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.5), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.7), -2.6em 0em 0 0em #01579b,
      -1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2);
  }
  87.5% {
    -webkit-box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.5),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.7), -1.8em -1.8em 0 0em #01579b;
    box-shadow: 0em -2.6em 0em 0em rgba(1, 87, 155, 0.2), 1.8em -1.8em 0 0em rgba(1, 87, 155, 0.2), 2.5em 0em 0 0em rgba(1, 87, 155, 0.2),
      1.75em 1.75em 0 0em rgba(1, 87, 155, 0.2), 0em 2.5em 0 0em rgba(1, 87, 155, 0.2), -1.8em 1.8em 0 0em rgba(1, 87, 155, 0.5),
      -2.6em 0em 0 0em rgba(1, 87, 155, 0.7), -1.8em -1.8em 0 0em #01579b;
  }
}
