.CreateCredit__SameHeight input {
  height: 35px;
}
.purchasedate_label_hide_wcag {
  position: absolute;
  width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}