.Gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.Gallery__thumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-preferred-size: 18%;
  flex-basis: 18%;
  margin-right: 3%;
  width: 100%;
  margin-left: 3%;
  padding: 0 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 3%;
  padding-left: 3%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 90vw;
  overflow-x: auto;
  border: 1px solid #ccc;
  max-height: 400px;
  overflow-y: auto;
}

.Gallery__thumb {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  opacity: 0.7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Gallery__thumb img {
  max-width: 45px;
  max-height: 45px;
}

.Gallery__thumb--selected {
  border: 1px solid #03a9f4;
  border-width: 1px;
  border-style: solid;
  border-color: #03a9f4;
  padding: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
  opacity: 1;
}

.Gallery__current-image {
  max-width: 290px;
  max-height: 100%;
}

.Gallery__image {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #01579b;
  -webkit-box-shadow: 1px 1px 1px #ccc;
  box-shadow: 1px 1px 1px #ccc;
  border-radius: 1%;
  max-width: 400px;
}

.Gallery__thumb:hover {
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .Gallery__thumbs {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin: 0 auto;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    overflow-x: scroll;
    padding: 0;
  }
  .Gallery__image {
    margin: 10px auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
  }
  .Gallery__thumb {
    margin: 0px 3px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 3px;
    margin-right: 3px;
  }
}