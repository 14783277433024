.Promotion__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 2% 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Promotion__group *:not(:first-child) {
  margin-left: 2%;
}

.Promotion__group .__Field {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.Promotion__group label {
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}

.Promotion__program-selection {
  max-width: 300px;
}

.Promotion__promotion-selection {
  max-width: 450px;
}

.Promotion__group .Select-menu-outer {
  margin-left: 0;
  max-height: 200px;
}

.fix-drop-down-overflow {
  position: absolute;
}

@media only screen and (max-width: 750px) {
  .Promotion__group {
    width: 100%;
  }
  .Promotion__group label {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .Promotion__promotion-selection {
    width: 100%;
  }
}
