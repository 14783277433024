.creditSelector .Select-value {
  line-height: 20px !important;
  padding-top: 10px !important;
}

.creditSelector .Select-option {
  border-bottom: solid lightgrey 1px;
  height: auto;
}

.purchaseDate input.form-control  {
  height: 35px;
}
