.BulletinSearchForm {
  display: flex;
  flex-direction: column;
}

.BulletinSearchForm__inputs {
  display: flex;
  flex-wrap: wrap;
}

.BulletinSearchForm *:first-child {
  margin-right: 5%;
}

.BulletinSearchForm__inputs .form-group .Select {
  width: 100%;
}

.BulletinSearchForm__inputs .form-group .Select-menu-outer * {
  width: 100%;
}

.BulletinSearchForm__inputs .form-group {
  display: flex;
  width: 400px;
  justify-content: space-between;
}

.BulletinSearchForm__inputs .form-group label {
  margin-right: 5px;
  width: 200px;
}

.BulletinSearchForm__buttons {
  display: flex;
  flex-wrap: wrap;
}

.BulletinSearchForm__buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BulletinSearchForm__buttons button svg {
  font-size: 2rem;
  margin-right: 10px;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  .BulletinSearchForm__inputs .form-group {
    flex-wrap: wrap;
    width: 80vw;
  }
}

.hr-customized {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #bdbdbd;
  margin: 3% 0;
  padding: 0;
}