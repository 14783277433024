.Footer {
  background-color: #000;
  color: rgba(255, 255, 255, 0.521);
  padding-top: 0.5%;
  padding-bottom: 1%;
  padding-right: 3%;
  padding-left: 3%;
  font-family: "SST W20 Roman", "SST W55 Regular", "Yu Gothic Medium", "YuGothic", sans-serif;
}

.Footer__copy-right {
  font-size: 1.2rem;
  text-align: right;
  margin: 0;
}

.Footer__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.browser__message{
    font-size: 1.5rem;
    margin-left: 10px;
}

.Footer__link {
  font-size: 1.2rem;
}
.Footer__link a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  position: relative;
} 
.Footer__link a:after {    
  bottom: 0;
  content: "";
  display: block;
  height: 0.5px;
  left: 0;
  position: absolute;
  background: #fff;
}
.Footer__link a:hover:after { 
  width: 100%;
}
.Footer__link a:focus { 
  text-decoration: none;
  color: #fff;
}

 .Footer__link a:hover {
  border: none;
  text-decoration: none;
  color: rgb(186, 181, 211);
  font-weight: 300;
}

.Footer__link:not(:last-child)::after {
  content: ' |';
  margin-right: 3px;
  margin-left: 3px;
  color: #fff;
  font-weight: 600;
}
