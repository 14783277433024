.overflow-scroll {
  max-height: 75vh;
  width: 100%;
}

.streach {
  width: 100%;
  width: 40vw;
  max-width: 700px;
}

.streach .streach {
  color: red;
}

.overflow-scroll>* {
  padding: 1em 0 0 1em;
  font-size: 1em;
}

.dropdown-submenu {
  padding: 1em;
  cursor: pointer;
}

.dropdown-submenu a {
  text-decoration: none;
}

.dropdown-submenu a:hover {
  text-decoration: none;
}

.dropdown-submenu a:active {
  text-decoration: none;
}

.dropdown-submenu a:focus {
  text-decoration: none;
}

.dropdown-submenu:hover {
  background-color: rgb(110, 106, 110, 0.3);
}

#catalog-dropdown~.dropdown-menu {
  padding: 0;
}