.block-cartempty {
  color: red;
  display: block;
}

.hide-cartempty {
  display: none;
}

.shippingAddress .Select-value {
  line-height: 20px !important;
  padding-top: 5px !important;
}

.shippingAddress .Select-option {
  border-bottom: solid lightgrey 1px;
  height: auto;
}

.shippingAddress .Select-menu-outer {
  max-height: 200px;
}

.shippingAddress .Select-menu {
  max-height: 200px;
}

.cartHeader {
  font-weight: bold;
  margin-bottom: 2px;
}

#cart table thead tr th,
#cart table tbody tr td {
  text-align: center;
}

.POUpload__Modal {
  max-width: 700px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 1% 6%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0 auto;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  background-color: #eeeeee;
}

.inline-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 1%;
  margin-bottom: 1%;
}

.inline-buttons *:not(:first-child) {
  margin-left: 3%;
}

.overflow-wrapper-two-direction {
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
  max-height: 120px;
  padding: 1% 2%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.POUpload__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(105, 102, 102, 0.75);
}

.container-less-fluid {
  padding-right: 50px;
  padding-left: 50px;
}

.Select-control {
  min-width: 150px;
}

.space-between-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.start-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.start-flex *:not(:last-child) {
  margin-right: 1%;
}

.error-container {
  width: 100%;
}

.error-container * {
  color: #E00000;
  font-weight: 600;
  white-space: pre-wrap;
  word-break: break-all;
}

.error-message {
  color: #E00000;
  font-weight: 600;
  white-space: pre-wrap;
  word-break: break-all;
}

@media only screen and (max-width: 700px) {

  .space-between-flex,
  .start-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .btn-small-screen {
    margin-top: 10px;
    width: 100%;
  }

  .Cart__common-error {
    text-align: left;
    left: 0;
  }
}

.Cart__preview-order-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media only screen and (max-width:1290px) {
  .Cart__preview-order-section {
    align-items: flex-start;
    margin-top: 1%;
    width: 100%;
  }
}

.disclaimer {
  color: #2F6FA7 !important;
  font-size: 1.3rem;
  font-weight: 700;
}

.Cart__common-error {
  width: 100%;
}


div.fixed-header-table-container {
  clear: both;
  overflow: auto;
  width: 100%;
  min-width: 900px;
  max-width: 1030px;

}

html>body div.fixed-header-table-container {
  overflow: hidden;
  width: 100%;
  min-width: 945px;
  max-width: 1030px;

}

div.fixed-header-table-container table {
  float: left;
  width: 100%;
  min-width: 900px;
  max-width: 1030px;

}

html>body div.fixed-header-table-container table {
  width: 100%;
  min-width: 900px;
  max-width: 1030px;

}

thead.fixed-header tr {
  position: relative;
}

thead.fixed-header th {
  padding: 4px 3px;
  text-align: left
}

html>body tbody.fixed-header-table-content {
  display: block;
  height: 350px;
  overflow: auto;
  width: 100%;
}

html>body thead.fixed-header {
  display: table;
  overflow: auto;
  width: 100%;
  border-bottom: 2px solid #e0e0e0;
}


tbody.fixed-header-table-content td,
tbody.fixed-header-table-content tr.normalRow td {
  padding: 10px 3px 10px 4px;
  border-top: 1px solid #e0e0e0;
}

tbody.fixed-header-table-content tr {
  margin-bottom: 10px;
}

tbody.fixed-header-table-content tr.alternateRow td {
  padding: 10px 3px 10px 4px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 2px;
  font-size: 2.5rem;
  font-weight: 600;
  transition: all .3s ease;
  padding: 1%;
  background: transparent;
  border: none;
}

.close-icon:hover {
  cursor: pointer;
  color: #ccc;
  transition: all .3s ease;
}
