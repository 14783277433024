.CountrySelect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 45px;
  position: absolute;
  top: 2px;
  right: 120px;
  z-index: 1000;
}
.CountrySelect__flag {
  width: 20px;
  height: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  border-radius: 400px;
}

.CountrySelect__flag--notSelected {
  /* -webkit-filter: grayscale(90%);
  filter: grayscale(90%); */
  opacity: 0.5;
}
.CountrySelect__flag:hover {
  cursor: pointer;
  /* -webkit-filter: grayscale(0%);
  filter: grayscale(0%); */
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
