.GoUpButton {
  background-color: #e1f5fe;
  border: 1px solid lightblue;
  padding: 3px;
  width: 40px;
  height: 40px;
  font-size: 3rem;
  border-radius: 400px;
  position: fixed;
  bottom: 5vh;
  right: 10px;
  -webkit-box-shadow: 1px 1px 1px #ccc;
  box-shadow: 1px 1px 1px #ccc;
}

.GoUpButton:hover {
  cursor: pointer;
}