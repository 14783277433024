.Side-By-Side-Menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-height: 50vh;
  background-color: #eeeeee;
}

.Side-By-Side-Menu__Main {
  padding-right: 1%;
  overflow-y: auto;
  width: 25vw;
  max-width: 250px;
  max-height: 50vh;
}

.Side-By-Side-Menu__Sub {
  padding-left: 2%;
  overflow-y: auto;
  -webkit-box-flex: 1;
  width: 25vw;
  max-width: 250px;
  max-height: 50vh;
  border-left: 1px solid #ccc;
}

.Side-By-Side-Menu__Sub__Main {
  font-weight: 600;
  border-bottom: 1px solid #263238;
  margin-bottom: 2%;
  display: inline-block;
}

.Side-By-Side-Menu li {
  list-style-type: none;
  width: 100%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 2%;
  margin-bottom: 2%;
}

.Side-By-Side-Menu__catalog-title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Side-By-Side-Menu__Main li:hover {
  font-weight: 600;
}

.Side-By-Side-Menu__Main li.selected {
  font-weight: 600;
  color: #01579b;
}

.Side-By-Side-Menu ul {
  padding: 0;
}

.Side-By-Side-Menu__Sub li {
  font-size: 1.3rem;
}

.Side-By-Side-Menu__Sub li:hover {
  font-weight: 400;
  text-decoration: underline;
  color: #29b6f6;
}

.Side-By-Side-Menu__Sub li.selected {
  color: #29b6f6;
  font-weight: 600;
}

.Side-By-Side-Menu__arrow {
  float: right;
  font-weight: 900;
}

@media (max-width:900px) {
  .Side-By-Side-Menu__Main {
    min-width: 25vw;
  }
  .Side-By-Side-Menu__Sub {
    min-width: 25vw;
  }
}