.AccessoryThumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 2% 1%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.AccessoryThumb__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 300px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 1px solid rgba(204, 204, 204, 0.521);
  padding-right: 1%;
  padding-left: 1%;
  margin-right: 3%;
}

.AccessoryThumb__info__sku {
  font-weight: 600;
}

.AccessoryThumb__image__container img {
  max-width: 100px;
  max-height: 100px;
}

.AccessoryThumb__image__container:hover {
  opacity: .7;
  border: .5px solid #fafafa;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.AccessoryThumb__image__container {
  background-color: #e0e0e0;
  width: 150px;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 1px 1px #ccc;
  box-shadow: 0px 1px 1px 1px #ccc;
  margin-right: 3%;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.AccessoryThumb__cart__prices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.price {
  display: inline-block;
  font-weight: 600;
  font-size: 1.4rem;
  color: #212121;
}

.price__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 200px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.AccessoryThumb__cart__add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
}

.AccessoryThumb__cart__add-to-cart *:not(:last-child) {
  margin-right: 5px;
}

.AccessoryThumb__cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  min-width: 220PX;
}

.AccessoryThumb__info__description {
  color: #424242;
}

.input-has-error {
  border: 1px solid red;
  outline: none;
}

.btn-with-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.label-sm {
  font-size: 1.3rem;
  color: #424242;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AccessoryThumb__action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}